type NestedRecord<T> = {
  [K in keyof T]: T[K] extends object ? NestedRecord<T[K]> : string;
};

const createTransKeys = <T extends Record<string, any>>(
  obj: T,
  parentKey = ""
): NestedRecord<T> => {
  return Object.keys(obj).reduce((acc, key) => {
    const currentKey = parentKey ? `${parentKey}.${key}` : key;
    const value = obj[key];

    if (typeof value === "object" && !Array.isArray(value)) {
      acc[key] = createTransKeys(value, currentKey) as NestedRecord<
        T[typeof key]
      >;
    } else {
      acc[key] = currentKey as NestedRecord<T[typeof key]>;
    }

    return acc;
  }, {} as any);
};

import en from "public/locales/en/common.json";

// Use the function to create keys
const Trans = createTransKeys(en);

export default Trans;
